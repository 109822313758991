/* eslint-disable camelcase */
import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';

import { formatAvailabilityDate } from '../../../../utils/formatAvailabilityDate';
import {
  PetAge,
  PetGender,
  PetSize,
  PetSpayed,
  PetType,
} from '../../components/landing/hero/data';
import { OwnerPetV6 } from '../../types/ownerPets';
import { OwnerAddress } from '../../types/putOwner';
import { WalkPreferenceQuestion } from '../../types/servicePreferences';
import { QuizAnswer } from '../../types/bookingRequest';
import { MAX_NUMBER_OF_RECOMMENDED_CAREGIVERS, SignupFlowSource, UTMCampaign } from '../../../../constants';
import { ResponseWalkerProfile } from '@/transformers';

export type Partners = 'tractor-supply' | 'bright-horizons';
export type LandingPagePathname = 'pet-boarding' | 'pet-sitting' | 'pet-walking' | 'pet-drop-in';

export const initialAccessDetails: AccessDetails = {
  lockboxCode: null,
  notes: null,
  gateCode: null,
  // Default to someone will be home
  keyMode: [3],
  lockboxInfo: null,
  gateNotes: null,
};

export const initialPetValues: InitialBookingJourneyState['pet'] = {
  id: null,
  name: null,
  age: null,
  size: null,
  breed: null,
  type: null,
  gender: 'male',
  sterile: 'unspecified',
};

export const initialOwnerAddress = {
  addressLine1: '',
  addressLine2: '',
  city: null,
  postalCode: null,
  administrativeAreaLevel1: null,
  country: null,
  longitude: null,
  latitude: null,
};

export type Maybe<T> = T | null;

export type AccessDetails = {
  lockboxCode?: string;
  lockboxInfo?: string;
  keyMode?: number[];
  notes?: string;
  gateCode?: string;
  gateNotes?: string;
};

export const initialDays = {
  su: 0,
  m: 1,
  t: 2,
  w: 3,
  th: 4,
  f: 5,
  sa: 6,
} as const;

export const initialDaysAbbr = {
  Sun: 0,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
} as const;

export type BookingJourneyStep =
| 'ServiceDetails'
| 'Profile'
| 'SignIn'
| 'CodeVerification'
| 'CreateAccount'
| 'Register'
| 'AddPet'
| 'RequestService'
| 'PetDetails'
| 'CaregiverPreferences'
| 'RequestSummary'
| 'BookingConfirmation'
| 'AccountCreated'
| 'PaymentMethod'
| 'MatchPreference'
| 'EditProfile'
| 'AccessDetails'
| 'CaregiversRecommendation'
| 'MatchPreferenceSelection';

export const initialSchedule: InitialBookingJourneyState['schedule'] = {
  start: {
    value: null,
    label: null,
    id: null,
    startHour: null,
  },
  end: {
    value: null,
    label: null,
    id: null,
    endHour: null,
  },
  repeat: false,
  days: [],
  fillStrategy: 5,
  length: 20,
  selectedDays: [],
};

export const initialOwnerValues: InitialBookingJourneyState['owner'] = {
  id: null,
  email: null,
  uuid: null,
  address: initialOwnerAddress,
  phone: null,
};

export const initialCaregiverValues: InitialBookingJourneyState['caregiver'] = {
  uuid: null,
  photo: null,
  name: null,
  isPromoted: null,
  pickUp: false,
  petParentMatch: true,
  availability: {
    startDate: formatAvailabilityDate({}),
    endDate: formatAvailabilityDate({
      lastDay: true,
    }),
    disabledDays: [new Date()],
  },
};

type BookingJourneyAttribution = {
  utmCampaign?: `${LandingPagePathname}-page` | 'partners-page' | UTMCampaign.ProfileLink | UTMCampaign.BookButton;
  utmSource?: 'google' | 'web' | `partner-${Partners}` | 'city-page' | 'walker-profile-city-page';
  utmMedium?: 'booking-journey';
  signupFlowSource?: SignupFlowSource;
  adCreative?: string;
  adGroup?: string;
  adid?: string;
  gclid?: string;
}

export type NavigationState = {
  animationDirection: 'backward' | 'forward';
};

export type InitialBookingJourneyState = {
  firstRender?: boolean;
  tipAmount?: number;
  caregiverModal: {
    isOpen: boolean;
    walkerId?: number;
  };
  isOnDemand?: boolean;
  isMarketplaceEnabled?: boolean;
  isEzLandingEnabled?: boolean;
  billingAddress: OwnerAddress;
  journey: {
    allSteps?: BookingJourneyStep[];
    step?: BookingJourneyStep;
    nextStep?: BookingJourneyStep;
    previousStep?: BookingJourneyStep;
    bookingRequestId?: number;
    selectPaymentMethod?: boolean;
    missingFields?: {
      phone?: boolean;
      address?: boolean;
    };
  };
  pets: {
    cats: boolean;
    dogs: boolean;
  };
  owner: {
    id?: number;
    uuid?: string;
    email?: string;
    address?: OwnerAddress;
    phone?: string;
  };
  pet: {
    id?: number;
    name?: string;
    age?: PetAge['slug'];
    size?: PetSize['slug'];
    gender?: PetGender['slug'];
    breed?: string;
    type?: PetType['slug'];
    sterile?: PetSpayed['slug'];
  };
  caregiver: {
    uuid?: string;
    photo?: string;
    isPromoted?: boolean;
    name?: string;
    pickUp?: boolean;
    petParentMatch?: boolean;
    availability?: {
      startDate?: string;
      endDate?: string;
      disabledDays?: Date[];
    };
    sittingQuestionnaireSlug?: string;
    preferences?: WalkPreferenceQuestion[];
  };
  selectedPets: {
    [key: string]: null | OwnerPetV6;
  };
  notes: {
    pet?: string;
    caregiver?: string;
  };
  schedule?: {
    start?: {
      value?: string;
      label?: string;
      id?: string;
      startHour?: number;
    };
    end?: {
      value?: string;
      label?: string;
      id?: string;
      endHour?: number;
    };
    repeat?: boolean;
    days?: number[];
    length?: number;
    fillStrategy?: number;
    selectedDays?: string[];
  };
  overnightPreferences?: {
    questionnaireSlug?: string;
    questionnaireAnswers?: QuizAnswer[];
  };
  accessDetails?: AccessDetails;
  promoCode?: string;
  createdAt?: number;
  attribution?: BookingJourneyAttribution;
  recommendedCaregivers: RecommendedCaregiverWithSelection[];
  promoCodeDetails?: {
    isApplied: boolean;
    balance: number | null;
    successMessage: string | null;
  };
  totalValue?: {
    lowestPrice: number;
    highestPrice: number;
    showRange: boolean;
  };
  navigation: NavigationState;
  ownerPets?: OwnerPetV6[];
}

export type RecommendedCaregiver = ResponseWalkerProfile;

export type RecommendedCaregiverWithSelection = {
  selected: boolean;
  caregiver: ResponseWalkerProfile;
}

export const initialBookingJourneyState: InitialBookingJourneyState = {
  firstRender: true,
  caregiverModal: {
    isOpen: false,
    walkerId: null,
  },
  ownerPets: [], // Initialize ownerPets as empty array
  isOnDemand: false,
  isMarketplaceEnabled: false,
  isEzLandingEnabled: null,
  journey: {
    allSteps: [],
    step: 'Profile',
    nextStep: 'Register',
    previousStep: 'SignIn',
    bookingRequestId: null,
    selectPaymentMethod: false,
    missingFields: {
      phone: false,
      address: false,
    },
  },
  pet: initialPetValues,
  owner: initialOwnerValues,
  billingAddress: initialOwnerAddress,
  notes: {
    pet: null,
    caregiver: null,
  },
  tipAmount: 0,
  caregiver: initialCaregiverValues,
  pets: {
    cats: false,
    dogs: false,
  },
  selectedPets: {},
  schedule: initialSchedule,
  overnightPreferences: {
    questionnaireSlug: null,
    questionnaireAnswers: [],
  },
  accessDetails: initialAccessDetails,
  promoCode: null,
  createdAt: Date.now(),
  attribution: {
    utmMedium: 'booking-journey',
    utmSource: 'web',
    utmCampaign: 'pet-walking-page',
    adCreative: '',
    adGroup: '',
    adid: '',
    gclid: '',
  },
  recommendedCaregivers: [],
  promoCodeDetails: {
    isApplied: false,
    balance: null,
    successMessage: null
  },
  totalValue: {
    lowestPrice: 0,
    highestPrice: 0,
    showRange: false
  },
  navigation: {
    animationDirection: 'forward',
  },
};

type Actions = {
  setTipAmount: (values: typeof initialBookingJourneyState.tipAmount) => void;
  setCaregiverModal: (values: typeof initialBookingJourneyState.caregiverModal) => void;
  setNotes: (values: typeof initialBookingJourneyState.notes) => void;
  setJourney: (values: typeof initialBookingJourneyState.journey) => void;
  setJourneySteps: (steps: BookingJourneyStep[]) => void;
  setOwner: (values: typeof initialBookingJourneyState.owner) => void;
  setOvernightPreferences: (values: typeof initialBookingJourneyState.overnightPreferences) => void;
  setPet: (values: typeof initialBookingJourneyState.pet) => void;
  setPromoCode: (values: typeof initialBookingJourneyState.promoCode) => void;
  setIsOnDemand: (values: typeof initialBookingJourneyState.isOnDemand) => void;
  setFirstRender: (values: typeof initialBookingJourneyState.firstRender) => void;
  setSchedule: (values: typeof initialBookingJourneyState.schedule) => void;
  setAccessDetails: (values: typeof initialBookingJourneyState.accessDetails) => void;
  setBillingAddress: (values: typeof initialBookingJourneyState.billingAddress) => void;
  setSelectedPets: (values: typeof initialBookingJourneyState.selectedPets) => void;
  setPets: (values: typeof initialBookingJourneyState.pets) => void;
  setCreatedAt: (values: typeof initialBookingJourneyState.createdAt) => void;
  setAttribution: (values: typeof initialBookingJourneyState.attribution) => void;
  setAvailability: (values: typeof initialBookingJourneyState.caregiver.availability) => void;
  setCaregiver: (
    values: typeof initialBookingJourneyState.caregiver) => void;
  resetStore: (resetState: Actions & InitialBookingJourneyState) => void;
  setRecommendedCaregivers: (caregivers: RecommendedCaregiverWithSelection[]) => void;
  toggleCaregiverSelection: (caregiverId: string) => void;
  setPromoCodeDetails: (values: typeof initialBookingJourneyState.promoCodeDetails) => void;
  setTotalValue: (values: typeof initialBookingJourneyState.totalValue) => void;
  addProfileToRecommendedCaregivers: (profile: ResponseWalkerProfile) => void;
  setRecommendedCaregiversList: (profiles: ResponseWalkerProfile[]) => void;
  unselectAllCaregivers: () => void;
  setNavigation: (values: Partial<NavigationState>) => void;
  setIsMarketplaceEnabled: (value: boolean) => void;
  setIsEzLandingEnabled: (value: boolean) => void;
  setOwnerPets: (pets: OwnerPetV6[]) => void;
};

export const useBookingStore = create<Actions & InitialBookingJourneyState>()(
  devtools(
    persist(
      (set) => ({
        ...initialBookingJourneyState,
        setFirstRender: (newState) => set(
          () => ({ firstRender: newState })),
        setIsOnDemand: (state) => set(
          () => ({ isOnDemand: state })
        ),
        setPets: (newPets) => set(
          (state) => ({ pets: { ...state.pets, ...newPets } })),
        setOwner: (newOwner) => set(
          (state) => ({ owner: { ...state.owner, ...newOwner } })),
        setNotes: (newNotes) => set(
          (state) => ({ notes: { ...state.notes, ...newNotes } })),
        /* Allows selectedPets object to be cleared if needed,
           spread operator was persisting existing values
        */
        setAvailability: (newAvailability) => set(
          (state) => ({ caregiver: { ...state.caregiver, availability: { ...newAvailability } } })),
        setSelectedPets: (newSelectedPets) => set(
          () => ({ selectedPets: { ...newSelectedPets } })),
        setAccessDetails: (newAccessDetails) => set(
          (state) => ({ accessDetails: { ...state.accessDetails, ...newAccessDetails } })),
        setOvernightPreferences: (newOvernightPreferences) => set(
          () => ({ overnightPreferences: newOvernightPreferences })),
        setSchedule: (newSchedule) => set(
          (state) => ({ schedule: { ...state.schedule, ...newSchedule } })),
        setAttribution: (newAttribution) => set(
          (state) => ({ attribution: { ...state.attribution, ...newAttribution } })),
        setTipAmount: (newTip) => set(
          () => ({ tipAmount: newTip })),
        setBillingAddress: (newBillingAddress) => set(
          (state) => ({ billingAddress: { ...state.billingAddress, ...newBillingAddress } })),
        setPet: (newPet) => set(
          (state) => ({ pet: { ...state.pet, ...newPet } })),
        setPromoCode: (newPromoCode) => set(
          () => ({ promoCode: newPromoCode }),
        ),
        setCreatedAt: (newCreatedAt) => set(
          () => ({ createdAt: newCreatedAt })),
        setJourney: (newJourney) => set(
          (state) => ({ journey: { ...state.journey, ...newJourney } })),
        setJourneySteps: (steps) => set(
          (state) => ({ journey: { ...state.journey, allSteps: steps } })
        ),
        setCaregiver: (newCaregiver) => set(
          (state) => ({ caregiver: { ...state.caregiver, ...newCaregiver } })),
        setCaregiverModal: (newCaregiverModal) => set(
          (state) => ({
            caregiverModal: { ...state.caregiverModal, ...newCaregiverModal },
          })),
        resetStore: (resetState) => set(
          {
            ...initialBookingJourneyState,
            ...resetState,
          },
          true,
        ),
        setRecommendedCaregivers: (newCaregivers) => set(
          () => ({ recommendedCaregivers: newCaregivers })
        ),
        toggleCaregiverSelection: (caregiverId) => set(
          (state) => ({
            recommendedCaregivers: state.recommendedCaregivers.map(item => 
              item.caregiver.uuid === caregiverId 
                ? { ...item, selected: !item.selected }
                : item
            )
          })
        ),
        setPromoCodeDetails: (newPromoCodeDetails) => set(
          (state) => ({ 
            promoCodeDetails: { 
              ...state.promoCodeDetails, 
              ...newPromoCodeDetails 
            } 
          })
        ),
        setTotalValue: (newTotalValue) => set(
          () => ({ totalValue: newTotalValue })
        ),
        addProfileToRecommendedCaregivers: (profile) => set((state) => {
          const profileExists = state.recommendedCaregivers?.some(
            item => item.caregiver.uuid === profile.uuid
          );

          if (!profileExists) {
            return {
              recommendedCaregivers: [
                ...state.recommendedCaregivers,
                {
                  selected: true,
                  caregiver: profile
                }
              ]
            };
          }
          return state;
        }),

        setRecommendedCaregiversList: (profiles) => set(() => {
          if (profiles?.length) {
            const recommendedProfiles = profiles
              .filter(profile => !profile.isPromoted)
              .slice(0, MAX_NUMBER_OF_RECOMMENDED_CAREGIVERS + 1)
              .map(profile => ({
                selected: true,
                caregiver: profile
              }));
            
            return { recommendedCaregivers: recommendedProfiles };
          }
          return { recommendedCaregivers: [] };
        }),

        unselectAllCaregivers: () => set((state) => ({
          recommendedCaregivers: state.recommendedCaregivers.map(caregiver => ({
            ...caregiver,
            selected: false
          }))
        })),

        setNavigation: (newNavigation) => set(
          (state) => ({
            navigation: {
              ...state.navigation,
              ...newNavigation
            }
          })
        ),
        setIsMarketplaceEnabled: (value) => set(
          () => ({ isMarketplaceEnabled: value })
        ),
        setIsEzLandingEnabled: (value) => set(
          () => ({ isEzLandingEnabled: value })
        ),
        setOwnerPets: (pets) => set({ ownerPets: pets }),
      }),
      {
        name: 'wag-booking-journey',
        version: 11,
        // Migration example below, once we introduce a breaking change to the store
        // we can use this to migrate the persisted state
        // migrate: (persistedState, version) => {
        //   if (version === 1) {
        //     persistedState.newField = persistedState.oldField
        //     delete persistedState.oldField
        //   }
        //   return persistedState;
        // },
      },
    ),
  ),
);

// 
export const selectFilteredRecommendedCaregivers = (state: InitialBookingJourneyState) => 
  state.recommendedCaregivers
    .filter(({ caregiver }) => 
      state.caregiver?.uuid ? caregiver.uuid !== state.caregiver.uuid : true
    )
    .slice(0, MAX_NUMBER_OF_RECOMMENDED_CAREGIVERS);

export const selectSelectedRecommendedCaregivers = (state: InitialBookingJourneyState) => 
  state.recommendedCaregivers
    .filter(({ selected, caregiver }) => 
      selected && (state.caregiver?.uuid ? caregiver.uuid !== state.caregiver.uuid : true)
    )
    .slice(0, MAX_NUMBER_OF_RECOMMENDED_CAREGIVERS);

export const selectCurrentRecommendedCaregiver = (state: InitialBookingJourneyState): ResponseWalkerProfile | null => {
  if (!state.caregiver?.uuid || !state.recommendedCaregivers?.length) {
    return null;
  }

  const currentRecommendedCaregiver = state.recommendedCaregivers.find(
    ({ caregiver }) => caregiver.uuid === state.caregiver?.uuid
  );

  return currentRecommendedCaregiver?.caregiver ?? null;
};

export const selectCaregiversWithLowestAndHighestPrice = (state: InitialBookingJourneyState, service: string) => {
  if (!state || !service || typeof service !== 'string') {
    console.warn('Invalid state or service provided to selectCaregiversWithLowestAndHighestPrice.');
    return { lowest: null, highest: null };
  }

  const recommendedCaregivers = [
    ...selectSelectedRecommendedCaregivers(state),
    {caregiver: selectCurrentRecommendedCaregiver(state)}
  ];

  if (!Array.isArray(recommendedCaregivers) || recommendedCaregivers.length === 0) {
    return { lowest: null, highest: null };
  }

  // Function to safely parse the caregiver's service price
  const getServicePrice = (caregiver) => {
    if (!caregiver || !Array.isArray(caregiver?.rates?.services)) {
      return Infinity; // Assume high price if data is missing
    }

    const rawServicePrice = caregiver.rates.services.find(
      (s) => s?.label?.toLowerCase() === service
    )?.price;

    // Format the price: remove asterisk and decimals if present
    const parsedPrice = rawServicePrice
      ? parseFloat(rawServicePrice.replace(/[*$]/g, '').replace(/\.\d+/, ''))
      : Infinity; // Default to high price if no service price is available

    return isNaN(parsedPrice) ? Infinity : parsedPrice; // Return Infinity if parsedPrice is NaN
  };

  // Map caregivers to include parsed price, filtering out those with invalid price
  const caregiversWithPrices = recommendedCaregivers
    .map((item) => ({
      ...item,
      price: getServicePrice(item.caregiver),
    }))
    .filter((item) => item.price !== Infinity); // Exclude caregivers with missing/invalid prices
  
  if (caregiversWithPrices.length === 0) {
    return { lowest: null, highest: null };
  }

  // Sort caregivers by price
  caregiversWithPrices.sort((a, b) => a.price - b.price);

  return {
    lowest: caregiversWithPrices[0],
    highest: caregiversWithPrices[caregiversWithPrices.length - 1],
  };
};
